export const environment = {
  version: '3.0.1',
  production: false,
  isDebug: true,
  apiKey: '09894db210cc7e3f790b20efa5bcc854dc78f982221ed17f1febc80d254d4d77',
  apiUrl: 'https://eu-api.atlasxpadala.com/api',
  fremanUrl: 'https://devaxpfm-api.atlasxpadala.com/api',
  availmentTypes: [
    {
      "AvailmentTypeCode": "BBOX",
      "AvailmentType": "Balikbayan Box"
    },
    {
      "AvailmentTypeCode": "DEMINIMIS",
      "AvailmentType": "De Minimis Value"
    },
    {
      "AvailmentTypeCode": "NONE",
      "AvailmentType": "None"
    }
  ],
  senderTypes: [
    {
      "SenderTypeCode": "QFWA",
      "SenderType": "Qualified Filipinos While Abroad"
    },
    {
      "SenderTypeCode": "NQFWA",
      "SenderType": "Non-Qualified Filipinos While Abroad"
    }
  ],
  cardTypes: [
    {
      "CardTypeCode": "Visa",
      "CardType": "Visa",
      "ProcessFee": {
        "US": {
          "CC": 0.03,
          "DC": 0.03
        },
        "CA": {
          "CC": 0.024,
          "DC": 0.25
        }
      }
    },
    {
      "CardTypeCode": "Master Card",
      "CardType": "Master Card",
      "ProcessFee": {
        "US": {
          "CC": 0.03,
          "DC": 0.03
        },
        "CA": {
          "CC": 0.024,
          "DC": 0.25
        }
      }
    },
    {
      "CardTypeCode": "Amex",
      "CardType": "American Express",
      "ProcessFee": {
        "US": {
          "CC": 0.05,
          "DC": 0.05
        },
        "CA": {
          "CC": 0.024,
          "DC": 0.25
        }
      }
    },
    {
      "CardTypeCode": "Discover",
      "CardType": "Discover",
      "ProcessFee": {
        "US": {
          "CC": 0.05,
          "DC": 0.05
        },
        "CA": {
          "CC": 0.024,
          "DC": 0.25
        }
      }
    },
  ],
  cardRegex: {
    "Visa": /^(?:4[0-9]{12})(?:[0-9]{3})$/,
    "Master Card": /^(?:5[1-5][0-9]{14})$/,
    "Amex": /^(?:3[47][0-9]{13})$/,
    "Discover": /^6(?:011|5[0-9]{2})[0-9]{12}$/
  },
  CLIENT_TIMEZONE: 'CLIENT_TIMEZONE',
  CLIENT_COUNTRY: 'CLIENT_COUNTRY',
  SHOULD_TRACK_FREMAN_INVOICE: true,
  contact: {
    telephone_us: '+1 (800) 883-1998',
    telephone_canada: '+1 (888) 721-5298',
    telephone_uk: '+44 (2045) 711959',
    mobile_smart: '+63 930 226 7580',
    mobile_smart2: '+63 908 886 2554',
    mobile_globe: '+63 966 428 8618',
  },
  appStoreLink: {
    android: 'https://play.google.com/store/apps/details?id=com.axp.customer',
    ios: 'https://apps.apple.com/us/app/atlas-express-padala-mobile/id1585548899'
  },
  googleMapsAPIUrl: 'https://maps.googleapis.com/maps/api',
  googleMapAPIKey: 'AIzaSyCDqwAgErq1u7LfsyvdRVm14Fn7baGH1vU',
  gTagId: 'G-DF9VBX9J82',
  fbAppID: '694259568342840',
  currency: {
    "US": "$",
    "CA": "CAD"
  }
};
